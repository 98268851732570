var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "section-to-print"
    }
  }, [_c('center', _vm._l(_vm.data.result, function (item, i) {
    var _item$description, _item$description2;

    return _c('b-card', {
      key: i
    }, [_c('table', {
      staticClass: "table table-bordered"
    }, [_c('tr', [_c('th', {
      staticClass: "text-center bg-primary text-white",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b', [_vm._v(" Pilihan " + _vm._s(item.pilihan_ke) + " ")])])]), _c('tr', [_c('td', [_vm._v("Target Jurusan")]), _c('td', [_vm._v(_vm._s(item.major.name))])]), _c('tr', [_c('td', [_vm._v("Passing Grade")]), _c('td', [_vm._v(_vm._s(item.major.passing_grade))])]), _c('tr', [_c('td', [_vm._v("Daya Tampung")]), _c('td', [_vm._v(_vm._s(item.major.daya_tampung))])]), _c('tr', [_c('td', [_vm._v("Peminat")]), _c('td', [_vm._v(_vm._s(item.major.peminat))])]), _c('tr', [_c('td', {
      attrs: {
        "colspan": "2"
      }
    })]), _c('tr', [_c('td', [_vm._v("Jumlah Alumni")]), _c('td', [_vm._v(_vm._s(item.sum_alumni))])]), _c('tr', [_c('td', [_vm._v("Nilai Rata-Rata Raport")]), _c('td', [_vm._v(_vm._s(item.nilai_raport_average))])]), _c('tr', [_c('td', [_vm._v("Nilai Mapel Pendukung")]), _c('td', [_vm._v(_vm._s(item.nilai_mapel_pendukung))])]), _c('tr', [_c('td', [_vm._v("Nilai Piagam")]), _c('td', [_vm._v(_vm._s(item.nilai_piagam))])]), _c('tr', [_c('td', [_vm._v("Nilai Awal")]), _c('td', [_vm._v(_vm._s(item.nilai_awal))])]), _c('tr', [_c('td', [_vm._v("Nilai Akhir")]), _c('td', [_vm._v(_vm._s(item.nilai_akhir))])]), _c('tr', [_c('td', {
      attrs: {
        "colspan": "2"
      }
    })]), _c('tr', [_c('td', {
      attrs: {
        "rowspan": "2"
      }
    }, [_c('b', [_vm._v(" Hasil Kecocokan ")])]), _c('td', [_c('b-badge', {
      staticClass: "ml-1",
      attrs: {
        "variant": _vm.hasilKecocokan(item.nilai_awal) == 1 ? 'light-success' : 'light-danger'
      }
    }, [_vm._v(_vm._s(_vm.hasilKecocokan(item.nilai_awal) == 1 ? "Cocok" : "Tidak Cocok"))])], 1)]), _c('tr', [_c('td', [_c('div', {
      domProps: {
        "innerHTML": _vm._s((_item$description = item.description) === null || _item$description === void 0 ? void 0 : _item$description.kecocokan)
      }
    })])]), _c('tr', [_c('td', {
      attrs: {
        "rowspan": "2"
      }
    }, [_c('b', [_vm._v("Hasil Kelulusan")])]), _c('td', [_c('b-badge', {
      staticClass: "ml-1",
      attrs: {
        "variant": _vm.hasilKelulusan(item.nilai_akhir, item.sum_alumni, item.major.passing_grade) == 1 ? 'light-success' : 'light-danger'
      }
    }, [_vm._v(_vm._s(_vm.hasilKelulusan(item.nilai_akhir, item.sum_alumni, item.major.passing_grade) == 1 ? "Lolos" : "Tidak Lolos"))])], 1)]), _c('tr', [_c('td', [_c('div', {
      domProps: {
        "innerHTML": _vm._s((_item$description2 = item.description) === null || _item$description2 === void 0 ? void 0 : _item$description2.kelulusan)
      }
    })])])])]);
  }), 1), _c('b-button', {
    attrs: {
      "id": "tombol-print",
      "block": ""
    },
    on: {
      "click": _vm.print
    }
  }, [_vm._v("Print")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }