var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-card', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Nama")]), _c('h4', [_vm._v(_vm._s(_vm.snbpDetail.name))]), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Asal Sekolah")]), _c('h4', [_vm._v(" " + _vm._s(_vm.snbpDetail.school_origin_info ? _vm.snbpDetail.school_origin_info.nama_sekolah : _vm.snbpDetail.school_origin) + " ")])]), _c('b-tabs', {
    attrs: {
      "content-class": "pt-1"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Hasil"
    }
  }, [_c('Hasil', {
    attrs: {
      "data": _vm.snbpDetail
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Ranking"
    }
  }, [_c('Ranking', {
    attrs: {
      "data": _vm.snbpDetail
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Raport"
    }
  }, [_c('Raport', {
    attrs: {
      "data": _vm.snbpDetail
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Piagam"
    }
  }, [_c('Piagam', {
    attrs: {
      "data": _vm.snbpDetail
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }