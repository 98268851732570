<template>
  <section>
    <h3 class="text-primary text-center mb-2">Ranking SNBP</h3>
    <b-row class="justify-content-center" v-if="myRanking">
      <b-col cols="6" sm="6" md="4" lg="3">
        <b-card class="bg-primary mt-3">
          <section class="d-flex justify-content-center align-items-center">
            <div style="margin-right: 10px">
              <feather-icon size="60" class="text-light" icon="BarChart2Icon" />
            </div>
            <div>
              <h4 class="media-heading text-white">
                <b> Rank Saya</b>
              </h4>
              <span class="text-white">
                <b> {{ myRanking.ranking.ke }}</b
                >/{{ myRanking.ranking.dari }}
              </span>
            </div>
          </section>
        </b-card>
      </b-col>
    </b-row>

    <b-overlay :show="loading">
      <b-card style="overflow: auto">
        <h4 class="text-center">Top 10 Rank</h4>

        <b-row class="mt-4">
          <b-col md="4" sm="4" class="">
            <label>Filter Jurusan</label>
            <v-select
              class="select-size-sm w-full"
              label="text"
              v-model="filterJurusan"
              @input="getRanking()"
              :clearable="true"
              :options="optJurusan"
            />
          </b-col>
          <b-col md="2" sm="2" class=""> </b-col>
          <b-col md="3" sm="3" class="">
            <b-form-group
              label="Filter Berdasarkan Sekolah Anda?"
              label-for="filter"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="filterSekolah"
                  @input="getRanking()"
                  switch
                  inline
                />
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col md="3" sm="3" class="">
            <b-form-group
              label="Filter Berdasarkan Provinsi Anda?"
              label-for="filter"
            >
              <b-form-group>
                <b-form-checkbox
                  v-model="filterProvinsi"
                  @input="getRanking()"
                  switch
                  inline
                />
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1">
            <table class="table table-bordered responsive">
              <tr class="bg-primary text-white" variant="primary">
                <th style="width: 3%" class="text-center">Rank</th>
                <th>Nama</th>
                <th>Target Jurusan 1</th>
                <th>Target Jurusan 2</th>
                <th class="text-center">Skor</th>
                <th class="text-center">Hasil</th>
              </tr>
              <tr v-for="(item, i) in snbpranking" :key="i">
                <td style="width: 3%" class="text-center">
                  {{ item.ranking.ke }}
                </td>
                <td>{{ item.name }}</td>
                <td>
                  <span v-if="item.majors[0]">
                    {{ item.majors[0]?.major?.name }}
                  </span>
                  <i class="text-danger" v-else> Tidak ada </i>
                </td>
                <td>
                  <span v-if="item.majors[1]">
                    {{ item.majors[1]?.major?.name }}
                  </span>
                  <i class="text-danger" v-else> Tidak ada </i>
                </td>
                <td class="text-center">
                  <strong>{{ item?.nilai_akhir }}</strong>
                </td>
                <td class="text-center">
                  <feather-icon
                    v-if="isMyResult(item)"
                    size="24"
                    class="text-primary cursor-pointer"
                    @click.prevent="viewDetailRanking(item)"
                    icon="EyeIcon"
                  ></feather-icon>
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>

      <!-- Modal -->
      <b-modal
        id="modal-detail-ranking"
        no-close-on-backdrop
        no-close-on-esc
        size="xl"
      >
        <b-row
          v-if="
            currentHasilRanking?.majors &&
            currentHasilRanking?.majors.length > 0
          "
        >
          <b-col
            lg="12"
            xl="6"
            v-for="(major, i) in currentHasilRanking?.majors"
            :key="i"
          >
            <section class="p-1">
              <h2 class="text-center bg-primary text-white py-1 mb-2">
                Pilihan Ke {{ major?.pilihan_ke }}
              </h2>
              <table class="table table-bordered">
                <tr>
                  <td>Target Jurusan</td>
                  <td>
                    <strong>{{ major?.major?.name }}</strong>
                  </td>
                  <td>Passing Grade</td>
                  <td>
                    <strong>{{ major?.major?.passing_grade }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Daya Tampung</td>
                  <td>
                    <strong>{{ major?.major?.daya_tampung }}</strong>
                  </td>
                  <td>Peminat</td>
                  <td>
                    <strong>{{ major?.major?.peminat }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Nilai Rata-Rata Raport</td>
                  <td>
                    <strong>{{ major?.nilai_raport_average }}</strong>
                  </td>
                  <td>Nilai Mapel Pendukung</td>
                  <td>
                    <strong>{{ major?.nilai_mapel_pendukung }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Nilai Piagam</td>
                  <td>
                    <strong>{{ major?.nilai_piagam }}</strong>
                  </td>
                  <td>Jumlah Alumni</td>
                  <td>
                    <strong>{{ major?.sum_alumni }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Nilai Awal</td>
                  <td>
                    <strong>{{ major?.nilai_awal }}</strong>
                  </td>
                  <td>Nilai Akhir</td>
                  <td>
                    <strong>{{ major?.nilai_akhir }}</strong>
                  </td>
                </tr>
                <tr v-if="major.description?.filter_by_sekolah">
                  <td>Ket Filter Sekolah</td>
                  <td colspan="3">
                    <div v-html="major.description?.filter_by_sekolah"></div>
                  </td>
                </tr>
                <tr v-if="major.description?.filter_by_provinsi">
                  <td>Ket Filter Provinsi</td>
                  <td colspan="3">
                    <div v-html="major.description?.filter_by_provinsi"></div>
                  </td>
                </tr>
              </table>
            </section>
          </b-col>
        </b-row>
      </b-modal>
      <!-- / -->
    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay,
  BMedia,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  // props: ["paketDataId"],
  components: {
    vSelect,
    BOverlay,
    BMedia,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    VBTooltip,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      snbpranking: [],
      myRanking: null,
      voucherData: [],
      filterRumpun: null,
      filterMajor: null,
      filterJurusan: null,
      filterSekolah: false,
      filterProvinsi: false,
      optRumpun: [],
      optMajor: [],
      optJurusan: null,
      // paketDataId: {
      //   name: null,
      // },
      form: {
        id: null,
        voucher_id: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "user.name", label: "Nama" },
        { key: "school.name", label: "Asal Sekolah" },
        { key: "majors[0].major.name", label: "Jurusan 1" },
        {
          key: "majors[0].nilai_akhir",
          label: "Nilai Akhir 1",
          sortable: true,
        },
        { key: "majors[1].major.name", label: "Jurusan 2" },
        {
          key: "majors[1].nilai_akhir",
          label: "Nilai Akhir 2",
          sortable: true,
        },
        // { key: "ranking.ke", label: "Ranking", sortable: true },
        // { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "text", value: "Voucher" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Voucher Paket",
      search: null,
      idd: null,
      loading: false,
      currentHasilRanking: {},
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    viewDetailRanking(hasilRanking) {
      this.currentHasilRanking = hasilRanking;
      this.$bvModal.show("modal-detail-ranking");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Voucher Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Voucher Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("snbp/createUpdateDeleteVoucher", [data])
            .then(() => {
              this.getRanking();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    async getRanking() {
      this.loading = true;
      const payload = {
        school_origin:
          this.filterSekolah != false ? this.user.detail.school_origin : null,
        provinsi:
          this.filterProvinsi != false && this.user.detail.school_origin_info
            ? this.user.detail.school_origin_info.provinsi
            : null,
        major_id: this.filterJurusan != null ? this.filterJurusan.value : null,
      };
      this.$store
        .dispatch("snbp/indexRanking", payload)
        .then((response) => {
          this.loading = false;
          let snbpranking = response.data.data;
          this.snbpranking = snbpranking;
          if (this.myRanking == null) {
            let myRanking = snbpranking.find((res) => {
              return res.user_id == this.user.id;
            });
            this.myRanking = myRanking;
          }
          if (this.optJurusan == null) {
            let optJurusan = [];
            this.snbpranking.forEach((el) => {
              el.majors.forEach((major) => {
                const existMajor = optJurusan.find(
                  (jurusan) => jurusan.value == major.major.id
                );
                if (!existMajor) {
                  optJurusan.push({
                    value: major ? major.major.id : "-",
                    text: major ? major.major.name : "-",
                  });
                }
              });
            });
            this.optJurusan = optJurusan;
          }

          this.totalRows = this.snbpranking.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    isMyResult(item) {
      const isSiswa = this.user?.level?.nama_level.toLowerCase() == "siswa";
      const isMyResult = this.user?.id == item.user_id;
      if (isSiswa && isMyResult) {
        return true;
      }

      return false;
    },
  },
  created() {},
  mounted() {
    this.getRanking();
  },
};
</script>

<style>
/* .scroll {
  max-height: 100px;
  overflow-y: scroll;
} */
</style>
