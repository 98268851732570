<template>
  <div class="">
    <b-card>
      <label for="">Nama</label>
      <h4>{{ snbpDetail.name }}</h4>
      <label for="">Asal Sekolah</label>
      <h4>
        {{
          snbpDetail.school_origin_info
            ? snbpDetail.school_origin_info.nama_sekolah
            : snbpDetail.school_origin
        }}
      </h4>
    </b-card>
    <b-tabs content-class="pt-1">
      <b-tab title="Hasil"> <Hasil :data="snbpDetail" /> </b-tab>
      <b-tab title="Ranking"> <Ranking :data="snbpDetail" /> </b-tab>
      <b-tab title="Raport"> <Raport :data="snbpDetail" /> </b-tab>
      <b-tab title="Piagam"> <Piagam :data="snbpDetail" /> </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BCard, BTabs, BTab } from "bootstrap-vue";
import Raport from "./components/Raport";
import Piagam from "./components/Piagam";
import Hasil from "./components/Hasil";
import Ranking from "./components/Ranking";
export default {
  components: {
    BCard,
    BTabs,
    BTab,
    Raport,
    Piagam,
    Hasil,
    Ranking,
  },
  data() {
    return {};
  },
  computed: {
    snbpDetail() {
      return this.$store.state.snbp.detail;
    },
  },
};
</script>

<style></style>
